import algoliasearch, {SearchClient} from 'algoliasearch';
import {createContext, PropsWithChildren, useContext, useMemo} from 'react';

const appId = 'NMFM7NEFWF';
const apiKey = 'c401656e57f2d8091010c2a3cd6cb027';
const searchClient = algoliasearch(appId, apiKey);

interface ContextType {
    client: SearchClient | null
}

const CONTEXT = createContext<ContextType>({client: null})
const useAlgoliaContext = () => useContext<ContextType>(CONTEXT)


export function useAlgoliaClient(): SearchClient {
    return useAlgoliaContext().client!
}

export const AlgoliaProvider = (props: PropsWithChildren<{}>) => {
    const client = searchClient
    const memoizedContext = useMemo(() => ({client}), [client])
    return (
        <CONTEXT.Provider value={memoizedContext}>
            {props.children}
        </CONTEXT.Provider>
    )
}
