import '../styles/globals.css'
import type {AppProps} from 'next/app'
import {SessionProvider} from 'next-auth/react';
import {useEffect, useState} from 'react';
import splitbee from '@splitbee/web';
import {AlgoliaProvider} from '@components/app-search/provider';
import {Toaster, ToasterProps} from 'react-hot-toast';
import {QueryClient, QueryClientProvider} from 'react-query';

const SPLITBEE_APP_ID = process.env.NEXT_PUBLIC_SPLITBEE_TOKEN;
const TOAST_CONFIG: ToasterProps = {
    toastOptions: {
        style: {maxWidth: '480px'},
        iconTheme: {
            primary: '#1E2D61',
            secondary: '#ffffff'
        }
    }
}

function MyApp({Component, pageProps: {session, ...pageProps}}: AppProps) {
    const [queryClient] = useState(() => new QueryClient())
    useEffect((): void => {
        if (SPLITBEE_APP_ID) {
            splitbee.init({
                apiUrl: '/sb-api',
                token: SPLITBEE_APP_ID,
                scriptUrl: '/sb.js',
                disableCookie: true
            });
        }
    }, []);
    return (
        <QueryClientProvider client={queryClient}>
            <SessionProvider session={session}>
                <AlgoliaProvider>
                    <Toaster {...TOAST_CONFIG} />
                    <Component {...pageProps} />
                </AlgoliaProvider>
            </SessionProvider>
        </QueryClientProvider>
    )
}

export default MyApp
